.logout {
        display: flex;
        align-items: center;
        cursor: pointer;

        & img {
            margin-right: 10px;
        }

        & span {
            font-style: normal;
            font-weight: 400;
            font-size: 18px;
            line-height: 160%;
        }
}